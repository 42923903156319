import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Features from "../components/Common/features"
import Clients from "../components/Common/clients"
import ClientForm from "../components/Offer/client-form"
import CompaniesHero from "../components/Companies/companies-hero"

const PriceListPage = () => (
  <Layout>
    <Seo title={"Dla firm"} description={"Współpracujemy z firmami w zakresie przewozów osobowych bezgotówkowych. Ułatwij transport swoim klientom oraz pracownikom."} />
    <div data-g="container">
      <CompaniesHero />

      <hr className="separator" style={{ marginBottom: "4rem" }}></hr>

      <Features />

      <ClientForm />

      <Clients />
    </div>
  </Layout>
)

export default PriceListPage
