import * as React from "react"
import { Link } from "gatsby"

const PricelistHero = () => {
  return (
    <div className={"companies-hero"}>
      <h1>Dla firm</h1>
      <div data-g="grid">
        <div data-g="4 12@sm 6@md">
          <p>
            Taxi Prawobrzeże zapewnia firmom ze Szczecina i okolic przejazdy
            bezgotówkowe zarówno dla Państwa pracowników jak i dla klientów.
            Przejazdy rozliczane w formie voucherów są wygodne, a ich
            rozliczanie jest cykliczne, przez co Twoja firma zaoszczędza czas na
            zarządzaniu finansami.
          </p>
        </div>
        <div data-g="4 12@sm 6@md">
          <p>
            Poza codziennymi kursami na terenie Szczecina i okolic zapewniamy
            także obsługę lotnisk na terenie Polski i Niemiec.
          </p>
        </div>
      </div>

      <div className="companies-hero__contact">
        Kontakt w sprawie obsługi biznesowej: +48 606 329 842
      </div>
    </div>
  )
}

export default PricelistHero
